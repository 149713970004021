export default async function getMenuData() {
  return [
    // {
    //   category: true,
    //   title: 'Dashboards',
    // },
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fe fe-home',
      url: '/dashboard',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      title: 'Profile',
      key: 'kyc',
      icon: 'fe fe-user',
      url: '/profile',
    },
    {
      title: 'Billings',
      key: 'billing',
      icon: 'fa fa-money',
      url: '/billing',
    },
    {
      title: 'Vending',
      key: 'utilities',
      icon: 'fa fa-cogs',
      url: '/vending',
    },
    {
      // <i class="fa-solid fa-wallet"></i>
      title: 'Wallet',
      key: 'wallet',
      icon: 'fe fe-pocket',
      // icon: 'fa fa-wallet',
      url: '/wallet',
    },
    {
      title: 'Visitors',
      key: 'visitors',
      icon: 'fa fa-users',
      url: '/visitors',
    },
    {
      title: 'Complaints',
      key: 'complaints',
      icon: 'fa fa-support',
      url: '/complaints',
    },
    {
      title: 'Transactions',
      key: 'transaction',
      // <i class="fa-solid fa-money-bills-simple"></i>,
      icon: 'fa fa-sort-amount-desc',
      url: '/transaction',
    },
    {
      title: 'Elections',
      key: 'election',
      // <i class="fa-solid fa-money-bills-simple"></i>,
      icon: 'fa fa-group',
      url: '/elections',
    },
  ]
}
