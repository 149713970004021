const actions = {
  SET_STATE: 'user/SET_STATE',
  ESTATE_PLUS_SAAS: 'user/ESTATE_PLUS_SAAS',
  LOGIN: 'user/LOGIN',
  SECURITY_LOGIN: 'user/SECURITY_LOGIN',
  REGISTER: 'user/REGISTER',
  FORGOT_PASSWORD: 'user/FORGOT_PASSWORD',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  LOGOUT: 'user/LOGOUT',
}

export default actions
