import { all, takeEvery, put, call } from 'redux-saga/effects'
// import { notification } from 'antd'
// import { history } from 'index'
import { notification } from 'antd'
import * as jwts from 'services/jwt'
import actions from './actions'

const jwt = {
  securityCheck: jwts.securityCheck,
  securityCheckDetails: jwts.securityCheckDetails,
}

export function* CHECK_DETAILS(data) {
  yield put({
    type: 'security/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.securityCheckDetails, data?.payload)
  if (success?.status) {
    yield put({
      type: 'security/SET_STATE',
      payload: {
        checkDetails: success,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'security/SET_STATE',
      payload: {
        checkDetails: {},
        loading: false,
      },
    })
    notification.error({ message: 'Error', description: success?.message })
  }
}

export function* SECURITY_CHECK(data) {
  yield put({
    type: 'security/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.securityCheck, data?.payload)
  if (success?.status) {
    yield put({
      type: 'security/SET_STATE',
      payload: {
        checkDetails: {},
        loading: false,
      },
    })
    notification.success({ message: 'Success', description: success?.message })
  }
  if (!success.status) {
    yield put({
      type: 'security/SET_STATE',
      payload: {
        checkDetails: {},
        loading: false,
      },
    })
    notification.error({ message: 'Error', description: success?.message })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CHECK_DETAILS, CHECK_DETAILS)])
  yield all([takeEvery(actions.SECURITY_CHECK, SECURITY_CHECK)])
}
