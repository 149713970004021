/* eslint-disable import/no-extraneous-dependencies */
import { Button, Cascader } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  // BankOutlined,
  CommentOutlined,
  DashboardOutlined,
  HomeOutlined,
  SafetyOutlined,
} from '@ant-design/icons'
import { history } from 'index'
import { RxCaretDown } from 'react-icons/rx'
import Jump from 'react-reveal/Jump'
import { FaWhatsapp } from 'react-icons/fa'
import logo from '../../../../image/estpls1.png'
// import GetStartedButton from '../buttons/GetStarted'

function Header() {
  const [spy, setSpy] = useState(1)
  setInterval(() => {
    setSpy(Math.floor(Math.random() * 100) + 1)
  }, 5000)

  const itemItems = [
    {
      value: 'administrative',
      label: (
        <Link to="/page/facility" className="w-100 text-decoration-none d-flex align-items-center">
          <DashboardOutlined className="text-new_primary" />
          <p className="text-new_primary m-0 p-0 pl-2">Administrative</p>
        </Link>
      ),
    },
    {
      value: 'residential',
      label: (
        <Link
          to="/page/residential"
          className="w-100 text-decoration-none d-flex align-items-center"
        >
          <HomeOutlined className="text-new_primary" />
          <p className="text-new_primary m-0 p-0 pl-2">Electricity Vending</p>
        </Link>
      ),
    },
    {
      value: 'security',
      label: (
        <Link to="/page/visitor" className="w-100 text-decoration-none d-flex align-items-center">
          <SafetyOutlined className="text-new_primary" />
          <p className="text-new_primary m-0 p-0 pl-2">Security Access Control</p>
        </Link>
      ),
    },
    {
      value: 'communication',
      label: (
        <Link
          to="/page/communication"
          className="w-100 text-decoration-none d-flex align-items-center"
        >
          <CommentOutlined className="text-new_primary" />
          <p className="text-new_primary m-0 p-0 pl-2">Issue & Communication</p>
        </Link>
      ),
    },
  ]

  const items = [
    // {
    //   value: 'Facility Management',
    //   label: (
    //     <li className="w-100 nav-link d-flex align-items-center">
    //       <BankOutlined className="text-new_primary" />
    //       <p className='text-new_primary m-0 p-0 pl-2'>Facility Management</p>
    //     </li>
    //   ),
    //   // children: itemItems,
    // },
    ...itemItems,
  ]
  return (
    <div className="w-full">
      <nav
        id="navbar"
        className="navbar navbar-expand-lg navbar-light bg-white shadow-sm fixed-top z-10"
      >
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img alt="logo" src={logo} width="90px" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0" />
            <div className="d-flex">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 align-items-center">
                <li className="nav-item">
                  <Cascader options={items} className="nav-item px-0">
                    <Link className="nav-link align-items-center" to="">
                      Products <RxCaretDown />
                    </Link>
                  </Cascader>
                  {/* <Dropdown menu={{ items }} className="nav-item px-0" trigger={['click', 'hover']}>
                  </Dropdown> */}
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/page/contacts">
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/page/sign-in">
                    My Account
                  </Link>
                </li>
                <li className="nav-item pt-1">
                  <Jump spy={spy}>
                    <Button
                      type="primary"
                      shape="round"
                      size="middle"
                      className="bg-primary-gradient text-white fw-bold"
                      onClick={() => history.push('/page/subscription')}
                    >
                      Get Started
                    </Button>
                  </Jump>
                </li>
                <li className="nav-item">
                  <a
                    href="https://drive.google.com/file/d/1JTxDVWOZ3gKfqWhQwJf4melsFO-2G9ym/view?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                    download
                  >
                    <Button shape="round" type="primary" className="bg-new_primary text-white">
                      Download App
                    </Button>
                  </a>
                </li>
                <li className="nav-item">
                  <Button
                    shape="round"
                    type="primary"
                    target="_blank"
                    // size='large'
                    icon={<FaWhatsapp />}
                    href="https://wa.me/+2348056074893"
                    className="bg-new_primary text-white"
                  >
                    Reach Us
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header
