import React from 'react'
import style from './style.module.scss'

const Footer = () => {
  const deviceYear = new Date().getFullYear()
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <a
          href="https://estateplusng.com"
          target="_blank"
          rel="noopener noreferrer"
          className={style.logo}
        >
          Estate PLus
        </a>
        <br />
        <p className="mb-0">
          Copyright © {deviceYear} Estate Plus |{' '}
          <a
            href="https://www.mediatec.org/privacy"
            className="text-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  )
}

export default Footer
