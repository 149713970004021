import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import dashboard from './dashboard/sagas'
import profile from './profile/sagas'
import wallet from './wallet/sagas'
import transaction from './transaction/sagas'
import billing from './billing/sagas'
import utilities from './utilities/sagas'
import visitors from './visitors/sagas'
import security from './security/sagas'
import complaints from './complaints/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    dashboard(),
    billing(),
    wallet(),
    security(),
    transaction(),
    profile(),
    utilities(),
    visitors(),
    complaints(),
  ])
}
