import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Bounce } from 'react-reveal'
import { BackTop, Button, Input } from 'antd'
import { FaFacebookSquare, FaInstagramSquare, FaTwitterSquare } from 'react-icons/fa'
import { ArrowUpOutlined } from '@ant-design/icons'
import { RiMailSendLine } from 'react-icons/ri'
import { history } from 'index'
import logo from '../../../../image/estpls1.png'
import appstore from '../../../../image/appstore.png'
import playstore from '../../../../image/playstore.png'
import plans from '../../../../image/plans.png'

function Footer() {
  const { pathname: path } = useLocation()
  return (
    <div className="w-100">
      {!path.includes('subscription') && (
        <div className="w-100 mb-5">
          <Bounce bottom>
            <div className="bg-city_bg text-white py-5">
              <div className="container">
                <div className="row d-flex">
                  <div className="col-md-7">
                    <p className="fs-2 fw-bold m-0">
                      <i>Get latest feature update on</i> <b>Estate Plus</b>
                    </p>
                  </div>
                  <div className="col-md-5">
                    <div className="d-flex pt-2">
                      <Input size="large" className="w-75" placeholder="Enter your email" />
                      <Button
                        type="primary"
                        size="large"
                        className="bg-new_primary text-white fw-bold w-25 text-center"
                      >
                        <span className="d-none d-md-block">Get Started</span>
                        <RiMailSendLine className="d-block d-md-none mx-auto" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Bounce>
          <Bounce bottom>
            <div className="w-100 bg-silver_primary">
              <div className="container">
                <div className="row">
                  <div className="col-md-5 py-5">
                    <p className="m-0 fs-2 lh-1 text-dark">Join the community of over</p>
                    <p className="m-0 fs-50p lh-1 text-dark fw-bolder">200,000+ users</p>
                    <p className="m-0 fs-1 lh-sm text-dark">making lives simpler and easier!</p>
                    <Button
                      type="primary"
                      shape="round"
                      size="large"
                      onClick={() => history.push('/page/subscription')}
                      className="bg-primary-gradient text-white fw-bold mt-5"
                    >
                      Get Started
                    </Button>
                  </div>
                  <div className="col-md-7 py-2">
                    <div className="w-100 text-center">
                      <img alt="plans" src={plans} className="w-75 img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Bounce>
        </div>
      )}

      <Bounce>
        <div className="container py-5">
          <div className="row">
            <div className="col-md-3">
              <img alt="logo" src={logo} className="img-fluid" width="100px" />
              <div className="py-3">
                <Link className="d-block text-black fw-normal my-2" to="/page/service">
                  About Us
                </Link>
                <Link className="d-block text-black fw-normal my-2" to="/page/faqs">
                  FAQs
                </Link>
                <Link className="d-block text-black fw-normal my-2" to="/page/service-single">
                  Newsletter
                </Link>
              </div>
              <div className="d-flex">
                <img alt="playstore" src={playstore} className="img-fluid mr-2" />
                <img alt="appstore" src={appstore} className="img-fluid ml-2" />
              </div>
              <div className="d-flex fs-2">
                <Link className="pe-2 text-new_primary">
                  <FaFacebookSquare />
                </Link>
                <Link className="pe-2 text-new_primary">
                  <FaInstagramSquare />
                </Link>
                <Link className="pe-2 text-new_primary">
                  <FaTwitterSquare />
                </Link>
              </div>
            </div>

            <div className="col-md-4">
              <p className="fs-5 fw-bold pt-4">Products</p>
              <div className="">
                <span className="d-block text-black fw-normal my-2">
                  Online electricity vending / Residents’ Self-Service
                </span>
                <span className="d-block text-black fw-normal my-2">
                  Billing for services/Demand Notice Generation
                </span>
                <span className="d-block text-black fw-normal my-2">
                  Collections and Bank Reconciliation
                </span>
                <span className="d-block text-black fw-normal my-2">
                  Service Provision Controls
                </span>
                <span className="d-block text-black fw-normal my-2">Issue Management</span>
                <span className="d-block text-black fw-normal my-2">Maintenance Planning</span>
                <span className="d-block text-black fw-normal my-2">
                  Bulk Power Monitoring and Reporting
                </span>
                <span className="d-block text-black fw-normal my-2">
                  Client/Resident Communications Tools
                </span>
                <span className="d-block text-black fw-normal my-2">
                  Visitor Management and Access Control
                </span>
                <span className="d-block text-black fw-normal my-2">Convenient Payment</span>
              </div>
            </div>

            <div className="col-md-3">
              <p className="fs-5 fw-bold pt-4">Contact us</p>
              <div className="">
                <span className="d-block text-black fw-normal my-2">
                  40B Sehinde Calisto Crescent, Off MM Int’l Airport Road, Lagos.
                </span>
                <a href="tel:+2348056074893" className="d-block text-black fw-normal my-2">
                  +234 805 607 4893
                </a>
                <a href="tel:+2349081315937" className="d-block text-black fw-normal my-2">
                  +234 908 131 5937
                </a>
                <a
                  href="mailto:connect@techend.com.ng"
                  className="d-block text-black fw-normal my-2"
                >
                  connect@techend.com.ng
                </a>
              </div>
            </div>

            <div className="col-md-2">
              <p className="fs-5 fw-bold pt-4">Legals</p>
              <div className="">
                <Link className="d-block text-black fw-normal my-2">Privacy Policy</Link>
                <Link className="d-block text-black fw-normal my-2">Terms and Conditions</Link>
                <Link className="d-block text-black fw-normal my-2">Disclosure</Link>
              </div>
            </div>
          </div>
        </div>
      </Bounce>

      <div className="w-100 d-flex justify-content-between px-3 px-md-5 text-center text-white py-2 bg-new_primary fw-bold">
        <p>
          Product of{' '}
          <a className="text-white" href="https://techend.com.ng/" target="__blank">
            Techend LTD
          </a>
        </p>
        <p>(c) {new Date().getFullYear()} Estate Plus. All Rights Reserved.</p>
      </div>

      <BackTop>
        <ArrowUpOutlined className="w-100 bg-new_primary btn btn-danger btn-lg rounded-circle" />
      </BackTop>
    </div>
  )
}

export default Footer
